<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-subheader>
        사이트 정보관리
      </v-subheader>
      <div v-if="siteInfo && siteInfo.diskUsage" class="pl-5">
        <div class="text-caption d-flex">
          {{ siteInfo.diskUsage.usageSize }}GB / {{ siteInfo.diskUsage.totalSize }}GB
          <v-spacer />
          {{ siteInfo.diskUsage.freeSize }}GB 남음 ({{ Math.round(100 - diskUsage) }}%)
        </div>
        <v-progress-linear
          :color="diskUsage < 50 ? 'green' : diskUsage < 85 ? 'warning' : 'error'"
          :value="diskUsage"
          class="mb-2"
        />
      </div>
      <v-sheet class="px-3 pb-0 d-flex" />
      <v-card>
        <v-card-text>
          <v-text-field
            v-model="siteInfo.siteName"
            label="Site Name."
          />
          <v-text-field
            v-model="siteInfo.siteTitle"
            label="Site Title."
          />
          <v-text-field
            v-model="siteInfo.buyFixedPoint"
            type="number"
            hide-spin-buttons
            label="Default Bought Point."
          />
          <v-text-field
            v-model="siteInfo.deliveryFixedPoint"
            type="number"
            hide-spin-buttons
            label="Default Delivery Point."
          />
          <v-radio-group v-model="siteInfo.compressImage" label="이미지 압축">
            <v-radio :value="true" label="사용" />
            <v-radio :value="false" label="사용 안함" />
          </v-radio-group>
          <v-file-input
            label="Logo."
            accept="image/*"
            @change="uploadImage"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            :width="$isMobile ? '100%' : ''"
            @click="updateSite"
          >
            저장
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ManageSiteContent',
    data () {
      return {
        siteInfo: {},
        logoImage: '',
      }
    },
    computed: {
      diskUsage () {
        let usage = 0
        if (this.siteInfo) {
          usage = this.siteInfo.diskUsage.usageSize / this.siteInfo.diskUsage.totalSize * 100
        }
        return usage
      },
    },
    beforeMount () {
      this.getSite()
    },
    methods: {
      async getSite () {
        const url = '/manage/site'
        await this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.siteInfo = res.data
            this.logoImage = this.siteInfo.logoImage
          }
        })
      },
      async getDefaultSite () {
        const url = '/site'
        await this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.$store.commit('site/setSiteInfo', res.data)
          }
        })
      },
      async updateSite () {
        const url = '/manage/site'
        await this.$axios.patch(url, this.siteInfo).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('사이트 정보를 수정하였습니다.')
            this.getDefaultSite()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(() => {
          alert('오류가 발생하였습니다.')
        })
      },
      async uploadImage (file) {
        if (file) {
          const formData = new FormData()
          formData.append('image', file)
          this.$axios.post('/attachment/upload', formData)
            .then(res => {
              if (res && res.data && res.data.uploaded === '1') {
                this.siteInfo.logoImage = this.api + '' +
                  '' +
                  'attachment/image/' + res.data.url
              }
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.siteInfo.logoImage = this.logoImage
        }
      },
    },
  }
</script>

<style scoped>

</style>
