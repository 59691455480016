<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManageSiteHeader />
      <ManageSiteContent />
    </v-container>
  </v-lazy>
</template>

<script>
  import ManageSiteHeader from '@/views/manage/site/Header'
  import ManageSiteContent from '@/views/manage/site/Content'
  export default {
    name: 'Index',
    components: { ManageSiteContent, ManageSiteHeader },
    data () {
      return {
      }
    },
  }
</script>

<style scoped>

</style>
